const tg = window.Telegram.WebApp;

export const useTelegram = () => {
    const onClose = () => {
        tg.close();
    };

    const onToggleButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    };

    return {
        tg,
        initData: tg.initData,
        user: tg.initDataUnsafe?.user,
        userId: tg.initDataUnsafe?.user?.id,
        queryId: tg.initDataUnsafe?.query_id,
        platform: tg.platform,
        onClose,
        onToggleButton,
    };
};
