import {Icon, Modal, Spin, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './post.scss';
import {FC, useEffect, useState} from 'react';
import {PostButton} from '../postButton';
import {IPostButton} from '../../../app/shared/types/postButton.interface';
import {CirclePlay, File, Pin, Play, PlayFill} from '@gravity-ui/icons';
import {PostProps} from './post.types';
import ModalWithMedia from '../modalWithMedia/modalWithMedia';
import {useAppSelector} from '../../../app/hooks/redux';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('post');

export const Post: FC<PostProps> = ({text, pinned, textAbove, media, channels, buttons, time}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [isModalWithMediaOpen, setIsModalWithMediaOpen] = useState(false);
    const {theme} = useAppSelector((store) => store.settingsSlice);

    const [linkTitle, setLinkTitle] = useState('');
    const [linkDescription, setLinkDescription] = useState('');
    const [linkImage, setLinkImage] = useState('');
    const [isLinkDataLoading, setIsLinkDataLoading] = useState(false);

    const timeNow = new Date().toLocaleTimeString('ru-RU', {
        hour: 'numeric',
        minute: 'numeric',
    });

    return (
        <div
            className={`${b()} ${b('background')} ${theme === 'light' ? b('background_light') : b('background_dark')}`}
        >
            <div className={b('container')}>
                <div
                    className={`${b('card')} ${theme === 'light' ? b('card_light') : b('card_dark')}`}
                >
                    <div className={b('title')}>
                        <Text color="positive">{channels[0].title}</Text>
                        <Text
                            variant="caption-2"
                            color="hint"
                        >{`${channels.length > 1 ? ` (+${channels.length - 1})` : ''}`}</Text>
                    </div>

                    {media && (media.type === 'document' || media.as_document) && (
                        <div className={b('file')}>
                            {media.type !== 'image' && media.type !== 'video' && (
                                <div className={b('file-icon')}>
                                    <Icon data={File} size={20} />
                                </div>
                            )}
                            {media.type === 'image' && (
                                <img
                                    className={b('file-image')}
                                    src={media.url}
                                    alt={media.name}
                                    onClick={() => setIsModalWithMediaOpen(true)}
                                />
                            )}
                            {media.type === 'video' && (
                                <video
                                    playsInline={true}
                                    autoPlay={true}
                                    muted={true}
                                    className={b('file-image')}
                                    onClick={() => setIsModalWithMediaOpen(true)}
                                >
                                    <source src={media.url} />
                                    {/* Формат не поддерживается */}
                                    {locale['post_file.video.format_error']}
                                </video>
                            )}
                            <div className={b('file-text-wrapper')}>
                                <Text variant="subheader-1">
                                    {media.name.length > 26
                                        ? media.name.slice(0, 26) + '...'
                                        : media.name}
                                </Text>
                                <Text variant="caption-2" color="hint">
                                    {(media.size / 1000).toFixed(2) + ' Мб'}
                                </Text>
                            </div>
                        </div>
                    )}
                    {media && media.type === 'image' && !media.as_document && !textAbove && (
                        <img
                            src={media.url}
                            className={b('media')}
                            onClick={() => setIsModalWithMediaOpen(true)}
                        />
                    )}
                    {media && media.type === 'video' && !media.as_document && !textAbove && (
                        <div
                            className={b('media-wrapper')}
                            onClick={() => setIsModalWithMediaOpen(true)}
                        >
                            <Icon data={PlayFill} size={10} className={b('media-icon')} />
                            <video
                                playsInline={true}
                                muted={true}
                                autoPlay={true}
                                className={`${b('media')} ${b('media_video')}`}
                            >
                                <source src={media.url} />
                                {/* Формат не поддерживается */}
                                {locale['post_file.video.format_error']}
                            </video>
                        </div>
                    )}
                    <div className={b('text')} dangerouslySetInnerHTML={{__html: text}}></div>
                    {media && media.type === 'image' && !media.as_document && textAbove && (
                        <img
                            src={media.url}
                            className={b('media')}
                            onClick={() => setIsModalWithMediaOpen(true)}
                        />
                    )}
                    {media && media.type === 'video' && !media.as_document && textAbove && (
                        <div
                            className={b('media-wrapper')}
                            onClick={() => setIsModalWithMediaOpen(true)}
                        >
                            <Icon data={PlayFill} size={10} className={b('media-icon')} />
                            <video
                                playsInline={true}
                                muted={true}
                                autoPlay={true}
                                className={`${b('media')} ${b('media_video')}`}
                            >
                                <source src={media.url} />
                                {/* Формат не поддерживается */}
                                {locale['post_file.video.format_error']}
                            </video>
                        </div>
                    )}
                    {/* Предпросмотр ссылки */}
                    {/* {link &&
                        !disablePreview &&
                        !media &&
                        (!isLinkDataLoading ? (
                            <div className={b('link')}>
                                <div className={b('link-container')}>
                                    <Text className={b('link-title')} as="p" variant="subheader-1">
                                        {locale['editor.link.preview']}
                                    </Text>
                                    <Text variant="caption-2" as="p">
                                        {link}
                                    </Text>
                                </div>
                            </div>
                        ) : (
                            <Spin size="xs" className={b('link-spin')} />
                        ))} */}
                    <div
                        className={`${b('info')} ${textAbove && !media?.as_document ? b('info_white') : ''}`}
                    >
                        {pinned && <Icon data={Pin} size={10} />}
                        <Text variant="caption-1">{time || timeNow}</Text>
                    </div>
                </div>
                {buttons &&
                    buttons.map((button: IPostButton, index) => (
                        <PostButton data={button} key={index} />
                    ))}
            </div>

            {media && (
                <ModalWithMedia
                    isOpen={isModalWithMediaOpen}
                    onClose={() => setIsModalWithMediaOpen(false)}
                    url={media.url}
                    type={media.type}
                    alt={media.name}
                />
            )}
        </div>
    );
};
