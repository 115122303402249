import {TextInput} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './linkButtonPopup.scss';
import {FC, useState} from 'react';
import {Popup} from '../popup';
import {LinkButtonPopupProps} from './linkButtonPopup.types';
import {postSlice} from '../../../app/store/slices/postSlice';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';

import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {
    yupSchemaLinkButtonPopupFormEn,
    yupSchemaLinkButtonPopupFormRu,
} from '../../../utils/validate';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('link-button-popup');

export const LinkButtonPopup: FC<LinkButtonPopupProps> = ({
    open,
    onClose,
    prevTitle = '',
    prevUrl = '',
    id,
}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [title, setTitle] = useState(prevTitle);
    const [url, setUrl] = useState(prevUrl);

    const {buttons} = useAppSelector((state) => state.postSlice);
    const {language} = useAppSelector((state) => state.settingsSlice);
    const {addButton, editButton} = postSlice.actions;
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({
        mode: 'onSubmit',
        values: {title, url},
        resolver: yupResolver(
            language === 'ru' ? yupSchemaLinkButtonPopupFormRu : yupSchemaLinkButtonPopupFormEn,
        ),
    });

    const handlePopupMainButton = () => {
        if (isValid) {
            if (id) {
                dispatch(editButton({id, title, url, type: 'link'}));
            } else {
                dispatch(addButton({type: 'link', title, url, id: buttons.length + 1}));
            }

            onClose();
            !id && setTitle('');
            !id && setUrl('');
        }
    };

    return (
        <Popup
            title={
                id
                    ? locale['popup.link-button.title.edit_button'] // 'Редактировать кнопку'
                    : locale['popup.link-button.title.add_button'] // 'Добавить кнопку'
            }
            open={open}
            onClose={() => {
                onClose();
                setTitle(prevTitle || '');
                setUrl(prevUrl || '');
            }}
            buttonText={id ? locale['popup.btn.save'] : locale['popup.btn.add']} // 'Сохранить' | 'Добавить'
            buttonView="outlined-info"
            onButtonClick={handleSubmit(handlePopupMainButton)}
            buttonTwoText={locale['popup.btn.cancel']} // Отмена
            isError={!!errors.title?.message || !!errors.url?.message}
        >
            <TextInput
                placeholder={locale['popup.link-button.button_text']} // Текст
                size="l"
                validationState={errors.title?.message ? 'invalid' : undefined}
                errorMessage={errors.title?.message}
                {...register('title', {
                    onChange(e) {
                        setTitle(e.target.value);
                    },
                    value: title,
                })}
            />
            <TextInput
                placeholder={locale['popup.link-button.link']} // Ссылка
                size="l"
                validationState={errors.url?.message ? 'invalid' : undefined}
                errorMessage={errors.url?.message}
                {...register('url', {
                    onChange(e) {
                        setUrl(e.target.value);
                    },
                    value: url,
                })}
            />
        </Popup>
    );
};
