import * as yup from 'yup';

export const yupSchemaLinkButtonPopupFormRu = yup.object().shape({
    title: yup
        .string()
        .required('Введите название')
        .max(32, 'Максимальное количество символов: 32'),
    url: yup.string().required('Введите ссылку').url('Введите корректный url'),
});

export const yupSchemaLinkButtonPopupFormEn = yup.object().shape({
    title: yup.string().required('Title is required').max(32, 'Maximum characters: 32'),
    url: yup.string().required('Link is required').url('Enter the correct url'),
});

export const yupSchemaHiddenContinuationPopupFormRu = yup.object().shape({
    title: yup
        .string()
        .required('Введите название')
        .max(32, 'Максимальное количество символов: 32'),
    guestText: yup.string().required('Введите текст для тех, кто не подписан на канал'),
    subscriberText: yup.string().required('Введите текст для подписчиков'),
});

export const yupSchemaHiddenContinuationPopupFormEn = yup.object().shape({
    title: yup.string().required('Title is required').max(32, 'Maximum characters: 32'),
    guestText: yup.string().required('Enter text for guests'),
    subscriberText: yup.string().required('Enter text for subscribers'),
});

export const yupSchemaPostEditorFormRu = yup.object().shape({
    text: yup.string().required('Введите текст'),
});

export const yupSchemaPostEditorFormEn = yup.object().shape({
    text: yup.string().required('Enter text'),
});

export const yupSchemaInputUrlForm = yup.object().shape({
    url: yup
        .string()
        .matches(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})|[a-zA-Z0-9]+\.[^\s]{2,}|(^$)/,
        ),
});
