import {Button, ButtonSize, Icon, Modal, Popover, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './popup.scss';
import {FC, PropsWithChildren} from 'react';
import {PopupProps} from './popup.types';
import {Xmark} from '@gravity-ui/icons';

const b = block('popup');

export const Popup: FC<PropsWithChildren<PopupProps>> = ({
    open,
    onClose,
    title,
    buttonText,
    buttonView,
    // disabled = false,
    onButtonClick,
    buttonTwoText,
    buttonTwoView = 'flat',
    isLoading = false,
    isError = false,
    children,
}) => {
    const buttonSize: ButtonSize = buttonText && buttonTwoText ? 'm' : 'l';

    return (
        <Modal open={open} onClose={onClose} className={b()} contentClassName={b('content')}>
            <Button size="m" view="flat" onClick={onClose} className={b('close-button')}>
                <Icon data={Xmark} size={16} />
            </Button>
            <div className={b('container')}>
                <Text variant="subheader-3" className={b('title')}>
                    {title}
                </Text>
                {children}
            </div>
            <div className={b('button-container')}>
                {buttonText && (
                    <Button
                        width="max"
                        disabled={isLoading || isError}
                        view={buttonView}
                        onClick={onButtonClick}
                        size={buttonSize}
                    >
                        {buttonText}
                    </Button>
                )}
                {buttonTwoText && (
                    <Button width="max" view={buttonTwoView} onClick={onClose} size={buttonSize}>
                        {buttonTwoText}
                    </Button>
                )}
            </div>
        </Modal>
    );
};
