import block from 'bem-cn-lite';
import './postPreview.scss';

import TgButton from '../../ui/tgButton/tgButton';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {screensSlice} from '../../../app/store/slices/screensSlice';
import {Button, Card, Icon, Sheet, Switch, Text} from '@gravity-ui/uikit';
import {UserLabel} from '../../ui/userLabel';
import {Post} from '../../ui/post';
import {Bell, ChevronDown, Clock, Link, Pin, TrashBin} from '@gravity-ui/icons';
import {postSlice} from '../../../app/store/slices/postSlice';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {IChannel} from '../../../app/shared/types/channel.interface';
import {useEffect, useState} from 'react';
import BackButton from '../../ui/backButton/backButton';
import {MobileDatePicker} from '../../ui/mobileDatePicker/mobileDatePicker';
import moment from 'moment';
import {Popup} from '../../ui/popup';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('post-preview');

export const PostPreview = () => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [mainButtonText, setMainButtonText] = useState(locale['main_button.publish']);
    const {
        text,
        channels,
        textAbove,
        disablePreview,
        media,
        buttons,
        notifiable,
        delay,
        pinned,
        link,
        id,
        published,
    } = useAppSelector((store) => store.postSlice);

    const {setScreen} = screensSlice.actions;
    const {setNotifiable, setPinned, resetState, setDelay, setDisablePreview} = postSlice.actions;
    const dispatch = useAppDispatch();

    const [
        createPost,
        {
            isSuccess: isCreatePostSuccess,
            isLoading: isCreatePostLoading,
            error: createPostError,
            isError: isCreatePostError,
        },
    ] = publisherApi.useCreatePostMutation();
    const [
        editPost,
        {
            isSuccess: isEditPostSuccess,
            isLoading: isEditPostLoading,
            error: editPostError,
            isError: isEditPostError,
        },
    ] = publisherApi.useEditPostMutation();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isEditDateSheetVisible, setIsEditDateSheetVisible] = useState(false);
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

    // Сброс состояний поста и изменение экрана при успешном запросе
    useEffect(() => {
        if (isCreatePostSuccess || isEditPostSuccess) {
            dispatch(setScreen('home'));
            dispatch(resetState());
        }
    }, [isCreatePostSuccess, isEditPostSuccess]);

    // Обработчик главной кнопки
    const handleMainButton = () => {
        if (!isCreatePostLoading && !isEditPostLoading) {
            const postData = {
                text: text,
                channels: channels.map((channel: IChannel) => {
                    return {id: channel.id, type: channel.type};
                }),
                pinned: pinned,
                notifiable: notifiable,
                delay: delay,
                text_above: textAbove,
                disable_preview: disablePreview,
                buttons: buttons,
                media: media?.id,
            };

            id ? editPost({initData, postData, postId: id}) : createPost({initData, postData});
        }
    };

    // Изменение текста главной кнопки
    useEffect(() => {
        if (isCreatePostLoading || isEditPostLoading) {
            // 'Сохранение...' | 'Публикация поста...'
            id
                ? setMainButtonText(locale['main_button.save_loading'])
                : setMainButtonText(locale['main_button.publish_loading']);
        } else {
            // 'Сохранить' | 'Опубликовать'
            id
                ? setMainButtonText(locale['main_button.save'])
                : setMainButtonText(locale['main_button.publish']);
        }
    }, [isCreatePostLoading, isEditPostLoading, id]);

    // Обработка дат
    const date =
        delay &&
        new Date(delay * 1000).toLocaleDateString('ru-RU', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        });

    const time =
        delay &&
        new Date(delay * 1000).toLocaleTimeString('ru-RU', {
            hour: 'numeric',
            minute: 'numeric',
        });

    const dateNow = new Date(moment().format().slice(0, 16));

    const isToday =
        delay &&
        moment().format('DD.MM.YYYY') ===
            new Date(delay * 1000).toLocaleDateString('ru-RU', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
            });

    useEffect(() => {
        (isEditPostError || isCreatePostError) && setIsErrorPopupOpen(true);
    }, [isEditPostError, isCreatePostError]);

    return (
        <main className={b()}>
            <BackButton onClick={() => dispatch(setScreen('postEditor'))} />
            <Card view="filled" className={b('card')}>
                <UserLabel className={b('label')} />
                <Post
                    text={text}
                    pinned={pinned}
                    textAbove={textAbove}
                    media={media}
                    channels={channels}
                    buttons={buttons}
                    link={link}
                    disablePreview={disablePreview}
                />
                <div className={b('settings-container')}>
                    {/* Уведомление */}
                    <div className={b('settings-item')}>
                        <Icon size={16} data={Bell} />
                        <Text variant="body-1">{locale['preview.btn.notification']}</Text>
                        <Switch
                            checked={Boolean(notifiable)}
                            onChange={() => {
                                dispatch(setNotifiable(!notifiable));
                            }}
                            size="l"
                            className={b('switch')}
                        />
                    </div>

                    {/* Закрепить пост */}
                    <div className={b('settings-item')}>
                        <Icon size={16} data={Pin} />
                        <Text variant="body-1">{locale['preview.btn.pin']}</Text>
                        <Switch
                            checked={Boolean(pinned)}
                            onChange={() => {
                                dispatch(setPinned(!pinned));
                            }}
                            size="l"
                            className={b('switch')}
                        />
                    </div>

                    {/* Предпросмотр ссылок */}
                    <div className={b('settings-item')}>
                        <Icon size={16} data={Link} />
                        <Text variant="body-1">{locale['link_preview.title']}</Text>
                        <Switch
                            checked={Boolean(!disablePreview)}
                            onChange={() => {
                                dispatch(setDisablePreview(!disablePreview));
                            }}
                            size="l"
                            className={b('switch')}
                        />
                    </div>

                    {/* Время публикации */}
                    {!published && (
                        <div className={b('settings-item')}>
                            <Icon size={16} data={Clock} />
                            <Text variant="body-1">{locale['preview.btn.time']}</Text>
                            <Button
                                onClick={() =>
                                    delay
                                        ? setIsEditDateSheetVisible(true)
                                        : setIsDatePickerOpen(true)
                                }
                                view="flat"
                                className={b('switch')}
                            >
                                <Text color="info">
                                    {/* 'Сегодня' | 'Сейчас' */}
                                    {delay
                                        ? `${isToday ? locale['preview.select.today'] : date} в ${time}`
                                        : locale['preview.select.now']}
                                </Text>
                                <Icon data={ChevronDown} />
                            </Button>
                        </div>
                    )}
                </div>
            </Card>

            <MobileDatePicker
                isOpen={isDatePickerOpen}
                onSelect={(date: any) => dispatch(setDelay(date))}
                onCancel={() => setIsDatePickerOpen(false)}
                delay={delay}
            />

            {/* Меню обновления или удаления даты */}
            <Sheet
                visible={isEditDateSheetVisible}
                onClose={() => setIsEditDateSheetVisible(false)}
                className={b('sheet')}
            >
                <div className={b('sheet-container')}>
                    {/* Изменить */}
                    <Button
                        onClick={() => {
                            setIsEditDateSheetVisible(false);
                            setIsDatePickerOpen(true);
                        }}
                        size="xl"
                        width="max"
                        view="outlined"
                    >
                        <div className={b('button-child')}>
                            <Icon data={Clock} size={20} />
                            {locale['sheet.btn.change']}
                        </div>
                    </Button>

                    {/* Удалить */}
                    <Button
                        onClick={() => {
                            setIsEditDateSheetVisible(false);
                            dispatch(setDelay(null));
                        }}
                        size="xl"
                        width="max"
                        view="outlined-danger"
                    >
                        <div className={b('button-child')}>
                            <Icon data={TrashBin} size={20} />
                            {locale['sheet.btn.delete']}
                        </div>
                    </Button>
                </div>
            </Sheet>

            {/* Попап с сообщением ошибки создания или редактирования поста */}
            <Popup
                open={isErrorPopupOpen}
                onClose={() => setIsErrorPopupOpen(false)}
                title={locale['popup.create_post_error.title']} // Ошибка
                buttonTwoText={locale['popup.btn.ok']} // Ок
            >
                {editPostError && <Text>{editPostError?.toString()}</Text>}
                {createPostError && <Text>{createPostError?.toString()}</Text>}
            </Popup>

            <TgButton
                title={mainButtonText}
                onClick={handleMainButton}
                disabled={isDatePickerOpen || isEditDateSheetVisible}
            />
        </main>
    );
};
