import block from 'bem-cn-lite';
import './postEditor.scss';

import ChannelsSelect from '../../ui/channelsSelect/channelsSelect';
import {Button, Icon, Sheet, Spin, Text} from '@gravity-ui/uikit';
import {Camera, EyeSlash, File, Link, Plus, ThumbsUp} from '@gravity-ui/icons';
import LinkPreview from '../../ui/linkPreview/linkPreview';
import PostFile from '../../ui/postFile/postFile';
import TgButton from '../../ui/tgButton/tgButton';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {screensSlice} from '../../../app/store/slices/screensSlice';
import {useEffect, useRef, useState} from 'react';
import {HiddenContinuationPopup} from '../../ui/hiddenContinuationPopup';
import {LinkButtonPopup} from '../../ui/linkButtonPopup';
import PostButtonPreview from '../../ui/postButtonPreview/postButtonPreview';
import {IPostButton} from '../../../app/shared/types/postButton.interface';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {yupSchemaPostEditorFormEn, yupSchemaPostEditorFormRu} from '../../../utils/validate';
import {postSlice, resetState} from '../../../app/store/slices/postSlice';
import BackButton from '../../ui/backButton/backButton';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {Popup} from '../../ui/popup';
import TextEditor from '../../ui/textEditor/textEditor';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('post-editor');

export const PostEditor = () => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const {buttons, text, channels, link, media, published} = useAppSelector(
        (state) => state.postSlice,
    );
    const {language} = useAppSelector((state) => state.settingsSlice);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFileAsDocument, setSelectedFileAsDocument] = useState(
        media ? media.as_document : false,
    );
    const [isSheetVisible, setIsSheetVisible] = useState(false);
    const [isAddButtonPopupOpen, setIsAddButtonPopupOpen] = useState(false);
    const [isHiddenContinuationPopupOpen, setIsHiddenContinuationPopupOpen] = useState(false);
    const [isLeaveEditorPopupOpen, setIsLeaveEditorPopupOpen] = useState(false);
    const [isErrorUploadFilePopupOpen, setIsErrorUploadFilePopupOpen] = useState(false);

    const [unformattedText, setUnformattedText] = useState('');
    const [channelsSelectErrorMessage, setChannelsSelectErrorMessage] = useState('');
    const {setScreen} = screensSlice.actions;
    const {setLink, setMedia} = postSlice.actions;
    const [
        uploadFile,
        {
            data: uploadedFileData,
            isSuccess: isUploadFileSuccess,
            isLoading: isUploadFileLoading,
            error: uploadFileError,
        },
    ] = publisherApi.useFileUploadMutation();
    const dispatch = useAppDispatch();

    const filePickerMedia = useRef<HTMLInputElement>(null);
    const filePickerDocument = useRef<HTMLInputElement>(null);

    const [inputFileKey, setInputFileKey] = useState<number>(Date.now());

    // Отрытие попапа с ошибкой добавления изображения
    useEffect(() => {
        uploadFileError && setIsErrorUploadFilePopupOpen(true);
    }, [uploadFileError]);

    // Поиск ссылки в тексте
    const tgUrlRegexp =
        /(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/;

    // useEffect(() => {
    //     const textToArr = text.split(' ');
    //     const url = textToArr.find((item: string) => item.match(tgUrlRegexp));
    //     url &&
    //         dispatch(
    //             setLink(
    //                 url
    //                     .replace(/<[^>]*>/g, '')
    //                     .replace(/(href=")/g, '')
    //                     .split('"')[0],
    //             ),
    //         );
    //     link && !url && dispatch(setLink(''));
    // }, [text]);

    // Загрузка файла
    useEffect(() => {
        if (selectedFile) {
            const data = new FormData();
            data.append('file', selectedFile);
            selectedFileAsDocument && data.append('as_document', '1');
            uploadFile({initData, data});
        }
    }, [selectedFile]);

    useEffect(() => {
        if (uploadedFileData && selectedFile) {
            dispatch(setMedia(uploadedFileData));
        }
    }, [uploadedFileData, selectedFile]);

    useEffect(() => {
        isUploadFileSuccess && setInputFileKey(Date.now());
    }, [isUploadFileSuccess]);

    // Редактирование медиа
    const handleEditMedia = () => {
        !media?.as_document && filePickerMedia.current !== null && filePickerMedia.current.click();
        media?.as_document &&
            filePickerDocument.current !== null &&
            filePickerDocument.current.click();
    };

    // Удаление медиа
    const handleDeleteMedia = () => {
        setSelectedFile(null);
        dispatch(setMedia(null));
        setInputFileKey(Date.now());
    };

    // Валидация
    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({
        mode: 'onSubmit',
        values: {text: unformattedText},
        resolver: yupResolver(
            language === 'ru' ? yupSchemaPostEditorFormRu : yupSchemaPostEditorFormEn,
        ),
    });

    const handleMainButtonClick = () => {
        if (isValid && channels.length > 0) {
            dispatch(setScreen('postPreview'));
        }
    };

    useEffect(() => {
        setChannelsSelectErrorMessage('');
    }, [channels]);

    const handleLeaveEditorButton = () => {
        dispatch(setScreen('home'));
        dispatch(resetState());
    };

    return (
        <main className={b()}>
            <section className={b('content')}>
                <BackButton
                    onClick={() => {
                        setIsLeaveEditorPopupOpen(true);
                    }}
                />
                <ChannelsSelect error={channelsSelectErrorMessage} disabled={!!published} />
                <TextEditor error={errors.text?.message} setUnformattedText={setUnformattedText} />
                {/* {link && !media && <LinkPreview url={link} />} */}
                {isUploadFileLoading && (
                    <div className={b('image-loader')}>
                        <Spin size="xs" />
                        <Text variant="caption-2" color="secondary">
                            {/* Загрузка файла... */}
                            {locale['editor.file_loading']}
                        </Text>
                    </div>
                )}
                {media && !isUploadFileLoading && (
                    <PostFile
                        url={media.url}
                        type={media.type}
                        name={media.name}
                        size={media.size}
                        asDocument={media.as_document}
                        deletable={!published}
                        onEdit={handleEditMedia}
                        onDelete={handleDeleteMedia}
                        isLoading={isUploadFileLoading}
                    />
                )}
                {buttons.length > 0 &&
                    buttons.map((button: IPostButton) =>
                        button.type === 'link' ? (
                            <PostButtonPreview
                                title={button.title}
                                type={button.type}
                                id={button.id}
                                url={button.url}
                                key={button.id}
                            />
                        ) : (
                            <PostButtonPreview
                                title={button.title}
                                type={button.type}
                                id={button.id}
                                subscriber={button.subscriber}
                                guest={button.guest}
                                key={button.id}
                            />
                        ),
                    )}
            </section>

            <div className={b('button-container')}>
                <Button
                    onClick={() => setIsSheetVisible(true)}
                    size="xl"
                    width="max"
                    view="outlined-info"
                >
                    <div className={b('button-child')}>
                        <Icon data={Plus} size={20} />
                        {/* Добавить кнопку */}
                        {locale['editor.btn.add_button']}
                    </div>
                </Button>

                {!media && !published && (
                    <div className={b('button-inline-wrapper')}>
                        <Button
                            onClick={() => {
                                setSelectedFileAsDocument(false);
                                filePickerMedia.current !== null && filePickerMedia.current.click();
                            }}
                            size="xl"
                            width="max"
                            view="outlined"
                        >
                            <div className={b('button-child')}>
                                <Icon data={Camera} size={20} />
                                {/* Фото/видео */}
                                {locale['editor.btn.add_media']}
                            </div>
                        </Button>

                        <Button
                            onClick={() => {
                                setSelectedFileAsDocument(true);
                                filePickerDocument.current !== null &&
                                    filePickerDocument.current.click();
                            }}
                            size="xl"
                            width="max"
                            view="outlined"
                        >
                            <div className={b('button-child')}>
                                <Icon data={File} size={20} />
                                {/* Файл */}
                                {locale['editor.btn.add_file']}
                            </div>
                        </Button>
                    </div>
                )}
                <input
                    onChange={(e) => {
                        e.target.files?.length && setSelectedFile(e.target.files[0]);
                    }}
                    type="file"
                    accept="image/*,.png,.jpg,.gif,.web,.heic,.mov,.mp4,.avi,.wmv,.webm"
                    className={b('hidden')}
                    ref={filePickerMedia}
                    key={inputFileKey + 'media'}
                />
                <input
                    onChange={(e) => {
                        e.target.files?.length && setSelectedFile(e.target.files[0]);
                    }}
                    type="file"
                    className={b('hidden')}
                    ref={filePickerDocument}
                    key={inputFileKey + 'file'}
                />
            </div>

            {/* Попап добавления кнопки со ссылкой */}
            <LinkButtonPopup
                open={isAddButtonPopupOpen}
                onClose={() => setIsAddButtonPopupOpen(false)}
            />

            {/* Попап добавления скрытого продолжения */}
            <HiddenContinuationPopup
                open={isHiddenContinuationPopupOpen}
                onClose={() => setIsHiddenContinuationPopupOpen(false)}
            />

            {/* Попап выхода из редактора */}
            <Popup
                title={locale['popup.leave_editor.title']} // Выйти из редактора?
                open={isLeaveEditorPopupOpen}
                onClose={() => setIsLeaveEditorPopupOpen(false)}
                buttonText={locale['popup.btn.exit']} // Выйти
                buttonView="outlined-danger"
                onButtonClick={handleLeaveEditorButton}
                buttonTwoText={locale['popup.btn.cancel']} // Отмена
            >
                <Text variant="body-1" color="complementary">
                    {/* Если вы выйдите, все изменения будут потеряны. */}
                    {locale['popup.leave_editor.text']}
                </Text>
            </Popup>

            {/* Попап ошибки загрузки изображения */}
            <Popup
                title={locale['popup.media_upload_error.title']} // Ошибка
                open={isErrorUploadFilePopupOpen}
                onClose={() => {
                    setIsErrorUploadFilePopupOpen(false);
                    setSelectedFile(null);
                    setInputFileKey(Date.now());
                }}
                buttonTwoText={locale['popup.btn.ok']} // Ок
            >
                <Text variant="body-1" color="complementary">
                    {typeof uploadFileError === 'string'
                        ? uploadFileError
                        : locale['popup.media_upload_error.text']}
                    {/* Не удалось загрузить файл */}
                </Text>
            </Popup>

            {/* Меню добавления кнопки */}
            <Sheet
                visible={isSheetVisible}
                onClose={() => setIsSheetVisible(false)}
                className={b('sheet')}
            >
                <div className={b('sheet-container')}>
                    <Button
                        onClick={() => {
                            setIsSheetVisible(false);
                            setIsAddButtonPopupOpen(true);
                        }}
                        size="xl"
                        width="max"
                        view="outlined"
                    >
                        <div className={b('button-child')}>
                            <Icon data={Link} size={20} />
                            {/* Кнопка со ссылкой */}
                            {locale['editor.btn.add_link']}
                        </div>
                    </Button>
                    <Button
                        onClick={() => {
                            setIsSheetVisible(false);
                            setIsHiddenContinuationPopupOpen(true);
                        }}
                        size="xl"
                        width="max"
                        view="outlined"
                    >
                        <div className={b('button-child')}>
                            <Icon data={EyeSlash} size={20} />
                            {/* Скрытое продолжение */}
                            {locale['editor.btn.add_hidden']}
                        </div>
                    </Button>
                </div>
            </Sheet>
            <TgButton
                title={locale['main_button.preview']} // Предпросмотр
                onClick={
                    channels.length > 0
                        ? handleSubmit(handleMainButtonClick)
                        : () => setChannelsSelectErrorMessage(locale['channel_select.error']) // Выберите каналы
                }
                disabled={
                    !!errors.text?.message || !!channelsSelectErrorMessage || isUploadFileLoading
                }
            />
        </main>
    );
};
