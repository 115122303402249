import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {IPostButton} from '../../shared/types/postButton.interface';
import {IPost} from '../../shared/types/post.interface';
import {IChannel} from '../../shared/types/channel.interface';
import {IPostMedia} from '../../shared/types/postMedia.interface';

interface IPostSlice extends Omit<IPost, 'deletable' | 'editable' | 'created_at'> {}

const initialState: IPostSlice = {
    id: undefined,
    text: '',
    channels: [],
    notifiable: true,
    pinned: false,
    delay: null,
    textAbove: false,
    disablePreview: true,
    buttons: [],
    media: null,
    link: undefined,
    published: false,
};

export const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        setId: (state, action: PayloadAction<number | undefined>) => {
            state.id = action.payload;
        },
        setText: (state, action: PayloadAction<string>) => {
            state.text = action.payload;
        },
        setLink: (state, action: PayloadAction<string>) => {
            state.link = action.payload;
        },
        setChannels: (state, action: PayloadAction<IChannel[] | []>) => {
            state.channels = [...action.payload];
        },
        deleteChannel: (state, action: PayloadAction<number>) => {
            state.channels = state.channels.filter(
                (channel: IChannel) => channel.id !== action.payload,
            );
        },
        setNotifiable: (state, action: PayloadAction<boolean>) => {
            state.notifiable = action.payload;
        },
        setPinned: (state, action: PayloadAction<boolean>) => {
            state.pinned = action.payload;
        },
        setDelay: (state, action: PayloadAction<null | number>) => {
            state.delay = action.payload;
        },
        setTextAbove: (state, action: PayloadAction<boolean>) => {
            state.textAbove = action.payload;
        },
        setDisablePreview: (state, action: PayloadAction<boolean>) => {
            state.disablePreview = action.payload;
        },
        addButton: (state, action: PayloadAction<IPostButton>) => {
            state.buttons = [...state.buttons, action.payload];
        },
        setButtons: (state, action: PayloadAction<IPostButton[]>) => {
            state.buttons = action.payload;
        },
        deleteButton: (state, action: PayloadAction<number>) => {
            state.buttons = state.buttons.filter(
                (button: IPostButton) => button.id !== action.payload,
            );
        },
        deleteAllButtons: (state) => {
            state.buttons = [];
        },
        editButton: (state, action: PayloadAction<IPostButton>) => {
            state.buttons = state.buttons.map((button: IPostButton) => {
                if (button.id === action.payload.id) {
                    if (button.type === 'link') {
                        return {...button, title: action.payload.title, url: action.payload.url};
                    } else {
                        return {
                            ...button,
                            title: action.payload.title,
                            guest: action.payload.guest,
                            subscriber: action.payload.subscriber,
                        };
                    }
                } else {
                    return button;
                }
            });
        },
        setMedia: (state, action: PayloadAction<IPostMedia | null>) => {
            state.media = action.payload;
        },
        setPublished: (state, action: PayloadAction<number | false>) => {
            state.published = action.payload;
        },
        updateState: (state, action: PayloadAction<IPost>) => {
            const {
                id,
                text,
                notifiable,
                pinned,
                delay,
                textAbove,
                disablePreview,
                buttons,
                media,
                link,
                published,
            } = action.payload;
            state.id = id;
            state.text = text;
            state.notifiable = notifiable;
            state.pinned = pinned;
            state.delay = delay;
            state.textAbove = textAbove;
            state.disablePreview = disablePreview;
            state.buttons = buttons;
            state.media = media;
            state.link = link;
            state.published = published;
        },
        resetState: (state) => {
            state.id = undefined;
            state.text = '';
            state.notifiable = true;
            state.pinned = false;
            state.delay = null;
            state.textAbove = false;
            state.disablePreview = false;
            state.buttons = [];
            state.media = null;
            state.link = undefined;
            state.published = false;
        },
    },
});

export default postSlice.reducer;
export const {
    setId,
    setText,
    setLink,
    setChannels,
    deleteChannel,
    setNotifiable,
    setPinned,
    setDelay,
    setTextAbove,
    addButton,
    setButtons,
    deleteAllButtons,
    deleteButton,
    editButton,
    setMedia,
    updateState,
    resetState,
} = postSlice.actions;
